import Vue from "vue";
import VueRouter from "vue-router";
import routesENG from "./routes/eng";
import routesPl from "./routes/pl";
import routesShared from "./routes/shared";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...routesPl, ...routesENG, ...routesShared],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
