import Vue from "vue";
import App from "./App";
import FlagIcon from "vue-flag-icon";
import VueYouTubeEmbed from "vue-youtube-embed";
import Functions from "./plugins/functions";

import router from "./router";
import store from "./store";

import { i18n } from "@/plugins/i18n";

Vue.use(FlagIcon);
Vue.use(VueYouTubeEmbed);

Vue.prototype.$imgUrl = (pic) => require("@/assets/img/" + pic);
Vue.prototype.$removeOrphans = Functions.removeOrphans;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
