<template>
  <article class="page page_wrap about">
    <div class="middle">
      <section class="content_box u_margin-top-110">
        <h1 class="heading-h1">{{ $t("pages.about.title1") }}</h1>
        <p>
          {{ $t("pages.about.p1") }}
        </p>
        <img
          class="u_margin-top-60"
          :src="$imgUrl('about/Mark&Ilona.jpg')"
          alt="pic"
        />
        <p>
          {{ $t("pages.about.p2") }}
        </p>
        <img
          class="u_margin-top-60"
          :src="$imgUrl('about/about_2.jpg')"
          alt="pic"
        />
        <h2 class="heading-h1 u_margin-top-60">
          {{ $t("pages.about.title2") }}
        </h2>
        <p>
          {{ $t("pages.about.p3") }}
        </p>
        <p v-html="$t('pages.about.p4')" />
        <img class="icon" :src="$imgUrl('misc/logo_tree.svg')" alt="icon" />
        <about-slider />
        <app-map />
        <p>
          {{ $t("pages.about.p5") }}
        </p>
        <p>
          {{ $t("pages.about.p6") }}
        </p>
        <p class="u_margin-bottom">
          {{ $t("pages.about.p7") }}
        </p>
      </section>
    </div>
  </article>
</template>
<script>
import AboutSlider from "@/components/about-slider";
import AppMap from "@/components/app-map";
import { getIdFromURL } from "vue-youtube-embed";

export default {
  methods: {
    getIdFromURL,
  },
  components: {
    AboutSlider,
    AppMap,
  },
};
</script>
