export default [
    {
        name: "HiBiSheBe",
        birth: 2021,
        height: "-",
        movies: [],
        pictures: [
            "HIBISHEBE_0.jpg",
            "HIBISHEBE_1.jpg",
            "HIBISHEBE_2.jpg",
            "HIBISHEBE_3.jpg",
            "HIBISHEBE_4.jpg",
            "HIBISHEBE_5.jpg",
            "HIBISHEBE_6.jpg",
        ],
        cover: "HIBISHEBE_0.jpg",
        thumb: "HIBISHEBE_thumb.jpg",
        sex: "Mare",
        tree: "Hibishebe_tree.svg",
        slug: "hibishebe",
        sport: "-",
        texts: [
            0,
            ["1a", { url: "chili-pj" }, "1b"],
            ["2a", { url: "hickstead-boy" }, "2b"],
        ],
    },
    {
        name: "Silvella",
        birth: 2022,
        height: "-",
        movies: [],
        pictures: ["SILVELLA_1.jpg", "SILVELLA_2.jpg", "SILVELLA_3.jpg", "SILVELLA_4.jpg", "SILVELLA_5.jpg", "SILVELLA_6.jpg", "SILVELLA_7.jpg"],
        cover: "SILVELLA_4.jpg",
        thumb: "SILVELLA_thumb.jpg",
        sex: "Mare",
        tree: "Silvella_tree.svg",
        slug: "silvella",
        sport: "-",
        texts: [],
    },
    {
        name: "Diamonda",
        birth: 2022,
        height: "-",
        movies: [],
        pictures: ["DIAMONDA_1.jpg", "DIAMONDA_2.jpg", "DIAMONDA_3.jpg", "DIAMONDA_4.jpg"],
        cover: "DIAMONDA_4.jpg",
        thumb: "DIAMONDA_thumb.jpg",
        sex: "Mare",
        tree: "Diamonda_tree.svg",
        slug: "diamonda",
        sport: "-",
        texts: [],
    },
    {
        name: "Clove Z",
        birth: 2022,
        height: "-",
        movies: [],
        pictures: ["CLOVE_Z_1.jpg", "CLOVE_Z_2.jpg", "CLOVE_Z_3.jpg", "CLOVE_Z_4.jpg", "CLOVE_Z_5.jpg"],
        cover: "CLOVE_Z_1.jpg",
        thumb: "CLOVE_Z_thumb.jpg",
        sex: "Mare",
        tree: "Clove_Z_tree.svg",
        slug: "clove-z",
        sport: "-",
        texts: [],
    },
    {
        name: "Cornelian",
        birth: 2022,
        height: "-",
        movies: [],
        pictures: ["CORNELIAN_1.jpg", "CORNELIAN_2.jpg", "CORNELIAN_3.jpg", "CORNELIAN_4.jpg", "CORNELIAN_5.jpg"],
        cover: "CORNELIAN_4.jpg",
        thumb: "CORNELIAN_thumb.jpg",
        sex: "Stallion",
        tree: "Cornelian_tree.svg",
        slug: "cornelian",
        sport: "-",
        texts: [],
    },
    {
        name: "Uptown Girl",
        birth: 2023,
        height: "-",
        movies: [],
        pictures: ["UPTOWN_GIRL_1.jpg", "UPTOWN_GIRL_2.jpg"],
        cover: "UPTOWN_GIRL_2.jpg",
        thumb: "UPTOWN_GIRL_thumb.jpg",
        sex: "Mare",
        tree: "Uptown_Girl_tree.svg",
        slug: "uptown-girl",
        sport: "-",
        texts: [],
    },
    {
        name: "Undefeated",
        birth: 2023,
        height: "-",
        movies: [],
        pictures: ["UNDEFEATED_1.jpg", "UNDEFEATED_2.jpg", "UNDEFEATED_3.jpg"],
        cover: "UNDEFEATED_2.jpg",
        thumb: "UNDEFEATED_thumb.jpg",
        sex: "Stallion",
        tree: "Undefeated_tree.svg",
        slug: "undefeated",
        sport: "-",
        texts: [],
    }
].map((horse) => ({
    ...horse,
    filter: 'youngsters'
}))