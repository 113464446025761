import Vue from "vue";
import Vuex from "vuex";
import horseData from "./horses";
import feedData from "./feeds";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu: [
      { id: 1, text: "Home", url: "home" },
      { id: 2, text: "About", url: "about" },
      { id: 3, text: "Horses", url: "horses" },
      { id: 4, text: "Facilities", url: "facilities" },
      { id: 5, text: "Offering", url: "offering" },
      { id: 6, text: "Contact", url: "contact" },
    ],
    horseData: null,
    feedData
  },
  getters: {
    getHorseData(state) {
      return state.horseData;
    },
    getFeedData(state) {
      return state.feedData;
    },
    getMenu(state) {
      return state.menu;
    },
  },
  mutations: {
    setData(state, payload) {
      state.horseData = payload
    }
  },
  actions: {
    updateDataLocale({ commit }, locale) {
      commit('setData', horseData(locale))
    }
  },
  modules: {},
});
