<template>
  <div class="menu-slider">
    <!-- Nav Links -->
    <div class="navMenu" :style="[menuDirection, menuWidth]">
      <div>
        <router-link
          @click.native="closeMenu()"
          v-for="link in $store.getters.getMenu"
          :key="link.id"
          tag="a"
          :to="{ path: path(link) }"
          active-class="active"
          class="menu_link"
          exact
        >
          {{ $t(`menu.${link.text}`) }}
        </router-link>
      </div>
      <lang-switch />
    </div>
    <!-- Hamburger Menu -->
    <nav
      :class="['navIcon', { open: menuActive }]"
      @click="toggleMenu()"
      :style="iconDirection"
    >
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </nav>
  </div>
</template>

<script>
import LangSwitch from "./lang-switch";
import { trim } from "lodash";

export default {
  name: "slider",
  props: {
    width: {
      type: Number,
      required: false,
      default: 250,
    },
    format: {
      type: String,
      required: false,
      default: "overlay",
    },
    direction: {
      type: String,
      required: false,
      default: "left",
    },
    opacity: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    menuWidth: {
      width: 0,
    },
    menuActive: false,
  }),
  components: {
    LangSwitch,
  },
  mounted() {
    window.addEventListener("resize", this.closeMenu);
  },
  computed: {
    menuDirection() {
      return this.direction === "right" ? { right: 0 } : { left: 0 };
    },
    iconDirection() {
      return this.direction === "right"
        ? {
            float: "right",
            margin: "10px",
            position: "fixed",
            top: "0.5rem",
            right: "20px",
            zIndex: 101,
          }
        : {
            float: "left",
            margin: "10px",
            position: "fixed",
            top: "0.5rem",
            right: "20px",
            zIndex: 101,
          };
    },
    app() {
      return document.getElementById("app");
    },
  },
  methods: {
    path({ url }) {
      return trim(`/${this.$t(`menu.url.${url}`)}`);
    },
    openMenu() {
      this.setFormat();
      this.menuActive = true;
      document.documentElement.style.overflow = "hidden";
    },
    setFormat() {
      const width = this.width.toString() + "px";
      if (this.format === "overlay") {
        this.menuWidth = { width: width };
      } else if (this.format === "full") {
        this.menuWidth = { width: "100%" };
      } else {
        this.menuWidth = { width: width };
        if (this.app) {
          if (this.direction === "right") {
            this.app.style.marginRight = width;
            this.app.style.transition = "margin-right .5s";
          } else {
            this.app.style.marginLeft = width;
            this.app.style.transition = "margin-left .5s";
          }
        }
      }
    },
    closeMenu() {
      this.menuWidth = { width: 0 };
      if (this.app) {
        this.menuActive = false;
        document.documentElement.style.overflow = "auto";
        this.app.style.marginLeft = "0";
        this.app.style.marginRight = "0";
      }
    },
    toggleMenu() {
      if (this.menuWidth.width === 0) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeMenu);
  },
};
</script>
