export default (lang) => ([
    {
        name: "Constable FRH",
        birth: 2018,
        height: "1.7m",
        movies: [
          "https://youtu.be/g1tc5-hwIYQ?si=EkqV5zpEHHJytlQ4",
          "https://youtu.be/0H_3E1YnHg0?si=pJgjZQLag_qKE32U",
          "https://youtu.be/AeX_Cpka6-Q?si=2L2j9zRAfe5Y0E7Z",
          "https://www.facebook.com/watch/?v=1884934378566770",
          "https://youtu.be/pcCAlUTmD0o?si=RyRTBvagApOmToWS",
          "https://youtu.be/-9wIB2GmHls?si=cZDVpX-psTqS90aE",
          "https://www.facebook.com/EquiClubZamlyniec/videos/1879391545541232"
        ],
        pictures: [
          "CONSTABLE_1.jpg",
          "CONSTABLE_2.jpg",
          "CONSTABLE_3.jpg",
          "CONSTABLE_4.jpg",
          "CONSTABLE_5.jpg",
          "CONSTABLE_6.jpg",
          "CONSTABLE_7.jpg",
          "CONSTABLE_8.jpg",
          "CONSTABLE_9.jpg",
          "CONSTABLE_10.jpg"
        ],
        cover: "CONSTABLE_3.jpg",
        thumb: "CONSTABLE_thumb.jpg",
        sex: "Stallion",
        tree: "Constable_tree.svg",
        slug: "constable-frh",
        sport: "1.30m sj",
        texts: [
          0,
          1,
          { bold: true, text: 2 },
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          [
            "11a",
            { url: "diascada" },
            "11b",
            { url: "gundi" },
            "11c",
            { url: "lordanz" },
            "11d",
          ],
          ["12a", { italic: true, text: "12b" }],
          13,
          { type: "link", url: 14 },
          { type: "link", url: 15 },
          { type: "link", url: 16 },
          ...(lang === "eng" ? [
            17,
            { type: "link", url: 18 },
            { type: "link", url: 19 },
            { type: "link", url: 20 },
            { type: "link", url: 21 },
            { type: "link", url: 22 },
            { type: "link", url: 23 },
            { type: "link", url: 24 },
            { type: "link", url: 25 }
          ] : [
            { type: "link", url: 17 },
            { type: "link", url: 18 },
            { type: "link", url: 19 },
            { type: "link", url: 20 },
            { type: "link", url: 21 }
          ])
        ],
        tableConfig: [
          ...(lang === "eng" ?
            [{ rows: 1 }, { rows: 1, onlyTitle: true }, { rows: 1 }, { rows: 1 }] :
            [{ rows: 2 }, { rows: 1 }, { rows: 1 }]
            )
        ]
      },
      {
        name: "Zillionaire",
        birth: 2019,
        height: "-",
        movies: [],
        pictures: [
          "ZILLIONAIRE_7.jpg",
          "ZILLIONAIRE_8.jpg",
          "ZILLIONAIRE_9.jpg",
          "ZILLIONAIRE_10.jpg",
          "ZILLIONAIRE_1.jpg",
          "ZILLIONAIRE_2.jpg",
          "ZILLIONAIRE_3.jpg",
          "ZILLIONAIRE_4.jpg",
          "ZILLIONAIRE_5.jpg",
          "ZILLIONAIRE_6.jpg",
          "ZILLIONAIRE_0.jpg",
        ],
        cover: "ZILLIONAIRE_7.jpg",
        thumb: "ZILLIONAIRE_thumb.jpg",
        sex: "Mare",
        tree: "Zillionaire_tree.svg",
        slug: "zillionaire",
        sport: "-",
        texts: [0, ["1a", { url: "elly-jo-pj" }, "1b"], 2],
      },
      {
        name: "Chaccolatte",
        birth: 2020,
        height: "-",
        movies: [],
        pictures: [
          "CHACCOLATTE_5.jpg",
          "CHACCOLATTE_6.jpg",
          "CHACCOLATTE_7.jpg",
          "CHACCOLATTE_8.jpg",
          "CHACCOLATTE_9.jpg",
          "CHACCOLATTE_10.jpg",
          "CHACCOLATTE_11.jpg",
          "CHACCOLATTE_12.jpg",
          "CHACCOLATTE_0.jpg",
          "CHACCOLATTE_1.jpg",
          "CHACCOLATTE_3.jpg",
          "CHACCOLATTE_4.jpg",
        ],
        cover: "CHACCOLATTE_13.jpg",
        thumb: "CHACCOLATTE_thumb.jpg",
        sex: "Mare",
        tree: "Chaccolatte_tree.svg",
        slug: "chaccolatte",
        sport: "-",
        texts: [0, ["1a", { url: "calinda-pj" }, "1b"], 2],
      },
].map((horse) => ({
    ...horse,
    filter: 'sport'
})))
