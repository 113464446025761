<template>
  <hooper
    :settings="hooperSettings"
    :style="{ height: 'auto' }"
    ref="hooper"
    @afterSlide="updateWidth"
  >
    <slide v-for="(img, index) in imgArray" :key="index">
      <div
        class="slider_home_bottom"
        :style="{
          'background-image': `url(${$imgUrl(`area/${img}`)})`,
        }"
      />
    </slide>
    <hooper-pagination slot="hooper-addons" />
  </hooper>
</template>
<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";

export default {
  data: () => ({
    hooperSettings: {
      centerMode: true,
      infiniteScroll: true,
      wheelControl: false,
      touchDrag: true,
      mouseDrag: false,
      autoPlay: true,
      playSpeed: "5000",
      hoverPause: false,
      trimWhiteSpace: true,
    },
    imgArray: [
      "area_1.png",
      "area_2.png",
      "area_4.png",
      "area_5.png",
      "area_6.png",
      "area_7.png",
      "area_12.png",
      "area_3.png",
    ],
  }),
  mounted() {
    this.$nextTick(() => {
      this.updateWidth();
    });
  },
  methods: {
    updateWidth() {
      this.$refs.hooper.updateWidth();
    },
  },
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
};
</script>
