<template>
  <router-link class="thumb_wrap horsey-gridey" :to="{ path }">
    <div class="image">
      <img
        :src="$imgUrl(`horse_thumbnails/${horse.thumb}`)"
        :alt="horse.thumb"
      />
    </div>
    <div class="name">
      <p>{{ horse.name }}</p>
      <img :src="$imgUrl('misc/arrow_right.svg')" alt="arrow" />
    </div>
  </router-link>
</template>

<script>
import { trim } from "lodash";

export default {
  props: {
    horse: {
      type: Object,
      defaulf: () => {},
    },
  },
  data() {
    return {
      path: `/${trim(this.$t("menu.url.horses"))}/${this.horse.slug}`,
    };
  },
};
</script>
