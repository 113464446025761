<template>
    <div class="horse-table">
        <h4 class="horse-table__title">{{ $t(`horse-table.${name}.title`) }}</h4>
        <div v-for="(item, i) in config" :key="i" class="u_width-100">
            <h5 :class="['horse-table__row-title', { 'horse-table__row-title--bold': item.onlyTitle }]">{{ $t(`horse-table.${name}.table.${i}.title`) }}</h5>
            <template v-if="!item.onlyTitle">
                <div v-for="(row, j) in Array(item.rows)" :key="j" class="horse-table__row">
                    <span class="horse-table__row-price">{{ $t(`horse-table.${name}.table.${i}.rows.${j}.price`) }}</span>
                    <span class="horse-table__row-description">{{ $t(`horse-table.${name}.table.${i}.rows.${j}.description`) }}</span>
                </div>
            </template>
        </div>
        <p class="horse-table__footer">{{ $t(`horse-table.${name}.footer`) }}</p>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        config: {
            type: Array,
            required: true
        }
    }
}
</script>
