<template>
  <article class="page_wrap page_map">
    <section class="middle">
      <h1 class="heading-h1">{{ $t("pages.contact.title") }}</h1>
      <p class="contact">{{ $t("pages.contact.company_name") }}</p>
      <p class="contact u_margin-bottom-30">
        {{ $t("pages.contact.nip") }}
      </p>
      <a
        :href="`tel:${$t('pages.contact.phone.number').split(' ').join('')}`"
        class="contact"
      >
        {{ $t("pages.contact.phone.title") }}
        {{ $t("pages.contact.phone.number") }}
      </a>
      <a
        :href="`mailto:${$t('pages.contact.mail')}`"
        class="contact u_margin-bottom-30"
      >
        {{ $t("pages.contact.mail") }}
      </a>
      <h3 class="heading-h3">{{ $t("pages.contact.address_title") }}</h3>
      <p class="contact">{{ $t("pages.contact.address1") }}</p>
      <p class="contact u_margin-bottom-30">
        {{ $t("pages.contact.address2") }}
      </p>
      <h3 class="heading-h3">
        {{ $t("pages.contact.headquarter_title") }}
      </h3>
      <p class="contact">{{ $t("pages.contact.headquarter1") }}</p>
      <p class="contact u_margin-bottom-30">
        {{ $t("pages.contact.headquarter2") }}
      </p>
    </section>
    <app-map />
  </article>
</template>

<script>
import AppMap from "@/components/app-map";

export default {
  components: { AppMap },
};
</script>
