export default [
    {
        name: "Gundi",
        birth: 2000,
        height: "1.72m",
        movies: [],
        pictures: [
          "GUNDI_1.jpg",
          "GUNDI_6.jpg",
          "GUNDI_0.jpg",
          "GUNDI_2.jpg",
          "GUNDI_3.jpg",
          "GUNDI_4.jpg",
          "GUNDI_5.jpg",
        ],
        cover: "GUNDI_0.jpg",
        thumb: "GUNDI_thumb.jpg",
        sex: "Mare",
        tree: "Gundi_tree.svg",
        slug: "gundi",
        sport: "1.45m sj",
        texts: [
          0,
          [
            "1a",
            { url: "lordanz" },
            "1b",
            { url: "diascada" },
            "1c",
            { url: "vasanti" },
            "1d",
            { url: "kookie" },
            "1e",
            { url: "chaccolindo" },
            "1f",
            { url: "constable-frh" },
            "1g",
          ],
          2,
          3,
          4,
        ],
      },
      {
        name: "Elly Jo PJ",
        birth: 2007,
        height: "1.68m",
        movies: ["https://youtu.be/FrMJQetgxXA", "https://youtu.be/OECTsxDXwFI"],
        pictures: [
          "ELLY_JO_PJ_1.jpg",
          "ELLY_JO_PJ_6.jpg",
          "ELLY_JO_PJ_0.jpg",
          "ELLY_JO_PJ_2.jpg",
          "ELLY_JO_PJ_3.jpg",
          "ELLY_JO_PJ_4.jpg",
          "ELLY_JO_PJ_5.jpg",
        ],
        cover: "ELLY_JO_PJ_0.jpg",
        thumb: "ELLY_JO_PJ_thumb.jpg",
        sex: "Mare",
        tree: "Elly_Jo_PJ_tree.svg",
        slug: "elly-jo-pj",
        sport: "1.35m sj",
        texts: [["0a", { url: "zillionaire" }, "0b"], 1, 2, 3, 4, 5],
      },
      {
        name: "Calinda PJ",
        birth: 2010,
        height: "1.7m",
        movies: [
          "https://youtu.be/msXElVm0whU",
        ],
        pictures: [
          "CALINDA_PJ_6.jpg",
          "CALINDA_PJ_0.jpg",
          "CALINDA_PJ_1.jpg",
          "CALINDA_PJ_3.jpg",
          "CALINDA_PJ_4.jpg",
          "CALINDA_PJ_5.jpg",
        ],
        cover: "CALINDA_PJ_0.jpg",
        thumb: "CALINDA_PJ_thumb.jpg",
        sex: "Mare",
        tree: "Calinda_PJ_tree.svg",
        slug: "calinda-pj",
        sport: "1.10m sj",
        texts: [0, 1, 2, 3, 4],
      },
      {
        name: "Chili PJ",
        birth: 2010,
        height: "1.64m",
        movies: [
          "https://youtu.be/hYcEN5IXkMA",
          "https://www.facebook.com/EquiClubZamlyniec/videos/814134022319039",
          "https://youtu.be/5UU4tJqQ1OI",
        ],
        pictures: [
          "CHILI_PJ_0.jpg",
          "CHILI_PJ_2.jpg",
          "CHILI_PJ_3.jpg",
          "CHILI_PJ_4.jpg",
          "CHILI_PJ_5.jpg",
        ],
        cover: "CHILI_PJ_0.jpg",
        thumb: "CHILI_PJ_thumb.jpg",
        sex: "Mare",
        tree: "Chili_tree.svg",
        slug: "chili-pj",
        sport: "1.25m sj",
        texts: [0, 1, 2, 3, 4],
      },
      {
        name: "Kookie",
        birth: 2018,
        height: "-",
        movies: [],
        pictures: ["KOOKIE_4.jpg", "KOOKIE_5.jpg", "KOOKIE_0.jpg", "KOOKIE_1.jpg", "KOOKIE_2.jpg", "KOOKIE_3.jpg"],
        cover: "KOOKIE_0.jpg",
        thumb: "KOOKIE_thumb.jpg",
        sex: "Mare",
        tree: "Kookie_tree.svg",
        slug: "kookie",
        sport: "-",
        texts: [
          0,
          ["1a", { url: "hibi-joy" }, "1b"],
          ["2a", { url: "gundi" }, "2b"],
          [
            "3a",
            { url: "lordanz" },
            "3b",
            { url: "diascada" },
            "3c",
            { url: "constable-frh" },
            "3d",
          ],
          4,
        ],
      },
].map((horse) => ({
    ...horse,
    filter: 'broodmares'
}))