import Vue from "vue";
import VueI18n from "vue-i18n";
import eng from "./eng.json";
import pl from "./pl.json";
import Functions from "./functions";

Vue.use(VueI18n);

const messages = {
  eng: eng,
  pl: pl,
};

export const i18n = new VueI18n({
  locale: "pl",
  messages,
  postTranslation: (str) => Functions.removeOrphans(str),
});
