<template>
  <hooper
    class="horse-slider"
    :settings="hooperSettings"
    :style="{ height: 'auto' }"
    ref="hooper"
    @afterSlide="updateWidth"
  >
    <slide v-for="(img, index) in pictures" :key="index">
      <div class="horse-slider__wrap">
        <img :src="$imgUrl(`${img}`)" alt="slider_photo" />
      </div>
    </slide>
    <hooper-navigation slot="hooper-addons" />
    <hooper-pagination slot="hooper-addons" mode="fraction" />
  </hooper>
</template>
<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";

export default {
  props: {
    pictures: {
      type: Array,
      default: () => [],
    },
  },
  data: ({ pictures }) => ({
    hooperSettings: {
      centerMode: false,
      infiniteScroll: pictures.length > 1,
      wheelControl: false,
      touchDrag: true,
      mouseDrag: false,
      autoPlay: pictures.length > 1,
      playSpeed: "5000",
      hoverPause: false,
      trimWhiteSpace: true,
    },
  }),
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
  },
  mounted() {
    this.$nextTick(() => {
      this.updateWidth();
    });
  },
  methods: {
    updateWidth() {
      this.$refs.hooper.updateWidth();
    },
  },
};
</script>
