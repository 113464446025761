<template>
  <article class="page page_wrap filters">
    <h1 class="heading-h1">{{ $t("pages.filters.title") }}</h1>
    <p class="text">{{ $t("pages.filters.text") }}</p>

    <single-filter :horse-array="broodmares">
      {{ $t("pages.filters.broodmares") }}
    </single-filter>
    <single-filter :horse-array="sport">
      {{ $t("pages.filters.sport") }}
    </single-filter>
    <single-filter :horse-array="youngsters">
      {{ $t("pages.filters.youngsters") }}
    </single-filter>
    <single-filter :horse-array="sold" id="no_border">
      {{ $t("pages.filters.sold") }}
    </single-filter>
  </article>
</template>

<script>
import SingleFilter from "../components/single-filter";

export default {
  computed: {
    horses: ({ $store }) => $store.getters["getHorseData"] || [],
    sport: ({ horses }) => horses.filter((item) => item.filter === "sport"),
    sold: ({ horses }) => horses.filter((item) => item.filter === "sold"),
    broodmares: ({ horses }) => horses.filter((item) => item.filter === "broodmares"),
    youngsters: ({ horses }) => horses.filter((item) => item.filter === "youngsters")
  },
  components: {
    SingleFilter,
  },
};
</script>
