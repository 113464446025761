<template>
  <div class="page_wrap horse-filter">
    <div class="horse_filter header" @click="showHorses()">
      <h2 class="heading-h2"><slot /></h2>
      <img
        :src="$imgUrl('misc/arrow_right.svg')"
        alt="arrow"
        class="arrow"
        ref="arrow"
      />
    </div>
    <transition name="slide">
      <div v-if="isVisible" class="horse_filter wrap">
        <div class="horse_filter thumbnail-box">
          <horse-thumb
            v-for="horse in horseArray"
            :key="horse.id"
            :horse="horse"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HorseThumb from "./horse-thumb";

export default {
  props: {
    horseArray: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isVisible: false,
  }),
  methods: {
    showHorses() {
      const arrow = this.$refs.arrow;
      this.isVisible = !this.isVisible;
      if (this.isVisible) {
        arrow.style.transform = "rotate(-90deg)";
      } else {
        arrow.style.transform = "rotate(90deg)";
      }
    },
  },
  components: {
    HorseThumb,
  },
};
</script>
