<template>
  <div class="movie-slider">
    <hooper :settings="hooperSettings" :style="{ height: this.height }">
      <slide v-for="(movie, index) in movies" :key="index">
        <div class="slider_responsive video">
          <video
            v-if="movieType(movie) === 'file'"
            muted
            loop
            playsinline
            controls
            preload="true"
          >
            <source :src="getMovieUrl(movie)" type="video/mp4" />
          </video>

          <div v-else-if="movieType(movie) === 'facebook'" class="fb-video">
            <iframe
              :src="parseFBLink(movie)"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allowFullScreen="true"
            />
          </div>

          <youtube
            v-else-if="movieType(movie) === 'youtube'"
            :video-id="getIdFromURL(movie)"
            class="youtube-video"
          />
          <instagram-embed
            v-else-if="movieType(movie) === 'instagram'"
            class="instagram-video"
            :url="movie"
            :hide-caption="true"
          />
        </div>
      </slide>
      <hooper-navigation slot="hooper-addons" />
      <hooper-pagination slot="hooper-addons" mode="fraction" />
    </hooper>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import { getIdFromURL } from "vue-youtube-embed";
import InstagramEmbed from "vue-instagram-embed";

export default {
  props: {
    movies: {
      type: Array,
      default: () => [],
    },
    height: {
      type: [Number, String],
      default: 0,
    },
  },
  data: ({ movies }) => ({
    hooperSettings: {
      centerMode: true,
      infiniteScroll: movies.length > 1,
      wheelControl: false,
      touchDrag: true,
      mouseDrag: false,
    },
  }),
  methods: {
    getMovieUrl(movie) {
      return require("@/assets/movies/" + movie);
    },
    movieType(movie) {
      let split = movie.split(".");
      if (split[split.length - 1] === "mp4") {
        return "file";
      } else if (split.includes("facebook")) {
        return "facebook";
      } else if (split.includes("instagram")) {
        return "instagram";
      }
      return "youtube";
    },
    getIdFromURL,
    parseFBLink(movie) {
      const regSlash = /\//gi;
      const regDot = /:/gi;
      let final = movie.replace(regSlash, "%2F");
      final = final.replace(regDot, "%3A");
      return `https://www.facebook.com/plugins/video.php?href=${final}&show_text=0`;
    },
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    InstagramEmbed,
    HooperPagination,
  },
};
</script>
