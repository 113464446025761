<template>
  <div class="thumb_wrap feeds">
    <img :src="$imgUrl(`feeds/${feed.picture}`)" v-bind:alt="feed.picture" />
    <div class="feeds_info">
      <div>
        <h3 class="heading-h3">{{ feed.name }}</h3>
        <!-- <ul class="list">
					<li v-for="i in feed.texts" :key="i">
						{{ $t(`texts.feeds.${feed.slug}.${i}`) }}
					</li>
				</ul> -->
        <p class="u_text-left u_margin-top-20">
          {{ $t(`texts.feeds.${feed.slug}.price`) }}:
          <span class="u_bold">
            {{ $t(`texts.feeds.${feed.slug}.value`) }}
          </span>
        </p>
      </div>
      <a v-if="feed.pdf" :href="getPdfUrl(feed.pdf)" download>
        <button class="button_primary">
          {{ $t("pages.feeds.button") }}
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feed: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getPdfUrl(pdf) {
      return "/pdf/" + pdf;
    },
  },
};
</script>
