import RouterHelper from "../../helpers/router-helper";

import About from "../../pages/About";
import Filters from "../../pages/Filters";

import Facilities from "../../pages/Facilities";
import Offering from "../../pages/Offering";
import Contact from "../../pages/Contact";

import Horse from "../../pages/horse";
import Feeds from "../../pages/Feeds";

const meta = {
  language: "pl",
  flag: "pl",
};

export default [
  {
    path: "/o-nas",
    component: About,
    meta,
  },
  {
    path: "/konie",
    component: RouterHelper,
    children: [
      {
        path: "",
        component: Filters,
        meta,
      },
      {
        path: ":horse",
        component: Horse,
        meta,
      },
    ],
  },
  {
    path: "/osrodek",
    component: Facilities,
    meta,
  },
  {
    path: "/oferta",
    component: Offering,
    meta,
  },
  {
    path: "/pasze",
    component: Feeds,
    meta,
  },
  {
    path: "/kontakt",
    component: Contact,
    meta,
  },
];
