<template>
  <article class="page page_wrap feeds">
    <h1 class="heading-h1">{{ $t("pages.offering.feed.title") }}</h1>
    <p class="text">{{ $t("pages.offering.feed.text2") }}</p>

    <div class="feeds_box">
      <feed-thumb v-for="feed in hayArray" :key="feed.id" :feed="feed" />
    </div>
  </article>
</template>

<script>
import FeedThumb from "@/components/feed-thumb";

export default {
  data() {
    return {
      hayArray: this.$store.getters["getFeedData"],
    };
  },
  computed: {
    lang: ({ $i18n }) => $i18n.locale,
  },
  created() {
    if (this.lang !== "pl") {
      this.$router.replace({ path: "/" });
    }
  },
  watch: {
    lang(val) {
      if (val !== "pl") {
        this.$router.replace({ path: "/" });
      }
    },
  },
  components: {
    FeedThumb,
  },
};
</script>
