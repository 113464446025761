<template>
  <hooper
    :settings="hooperSettings"
    :style="{ height: 'auto' }"
    ref="hooper"
    @afterSlide="updateWidth"
  >
    <slide>
      <div class="home_row1">
        <img :src="$imgUrl('home/ourhorses_small.jpg')" alt="photo" />
        <div class="feeds_info">
          <h3 class="heading-h3">
            {{ $t("pages.home.title2") }}
          </h3>
          <p class="list">
            {{ cutBody($t("pages.filters.text")) }}
          </p>
          <router-link tag="a" :to="{ path: path('horses') }">
            <button class="button_primary">
              {{ $t("pages.home.button_main") }}
            </button>
          </router-link>
        </div>
      </div>
    </slide>
    <slide>
      <div class="home_row1">
        <img :src="$imgUrl('home/facilities_small.jpg')" alt="photo" />
        <div class="feeds_info">
          <h3 class="heading-h3">
            {{ $t("pages.facilities.title") }}
          </h3>
          <p class="list">
            {{
              cutBody(
                $t("pages.facilities.header.text1") +
                  $t("pages.facilities.header.text2") +
                  $t("pages.facilities.main.p2")
              )
            }}
          </p>
          <router-link tag="a" :to="{ path: path('facilities') }">
            <button class="button_primary">
              {{ $t("pages.home.button_main") }}
            </button>
          </router-link>
        </div>
      </div>
    </slide>
    <slide>
      <div class="home_row1">
        <img :src="$imgUrl('home/offering_small.jpg')" alt="photo" />
        <div class="feeds_info">
          <h3 class="heading-h3">
            {{ $t("pages.offering.title") }}
          </h3>
          <p class="list">
            {{
              cutBody(
                $t("pages.offering.main.text1") +
                  $t("pages.offering.main.text2") +
                  $t("pages.offering.main.text3")
              )
            }}
          </p>
          <router-link tag="a" :to="{ path: path('offering') }">
            <button class="button_primary">
              {{ $t("pages.home.button_main") }}
            </button>
          </router-link>
        </div>
      </div>
    </slide>
    <hooper-navigation slot="hooper-addons" />
    <hooper-pagination slot="hooper-addons" mode="fraction" />
  </hooper>
</template>
<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import { trim } from "lodash";

const MAX_WORDS = 50;

export default {
  data: () => ({
    hooperSettings: {
      centerMode: false,
      infiniteScroll: true,
      wheelControl: false,
      touchDrag: true,
      mouseDrag: false,
      autoPlay: true,
      playSpeed: "5000",
      hoverPause: true,
      trimWhiteSpace: false,
    },
  }),
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
  },
  mounted() {
    this.$nextTick(() => {
      this.updateWidth();
    });
  },
  methods: {
    cutBody(text) {
      if (text.split(" ").length > MAX_WORDS) {
        const temp = text.split(" ");
        temp.splice(MAX_WORDS, temp.length, "...");
        return this.$removeOrphans(temp.join(" "));
      } else {
        return this.$removeOrphans(text);
      }
    },
    updateWidth() {
      this.$refs.hooper.updateWidth();
    },
    path(item) {
      return `/${trim(this.$t("menu.url." + item))}`;
    },
  },
};
</script>
