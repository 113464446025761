<template>
  <article class="page_wrap">
    <hooper :settings="hooperSettings" :style="{ height: 'auto' }">
      <slide v-for="(img, index) in carouselImages" :key="index">
        <section
          class="bg_full"
          :style="{
            'background-image': `url(${$imgUrl(img)})`,
          }"
        />
      </slide>
      <hooper-pagination slot="hooper-addons" />
    </hooper>
    <section class="label bg_white">
      <h1 class="heading-h1 horse_name">
        {{ $t("pages.facilities.title") }}
      </h1>
      <div class="label_row">
        <p class="item">{{ $t("pages.facilities.header.text1") }}</p>
        <p class="item">{{ $t("pages.facilities.header.text2") }}</p>
      </div>
    </section>
    <div class="middle">
      <section class="content_box u_margin-top-60">
        <h2 class="heading-h1">
          {{ $t("pages.facilities.main.title1") }}
        </h2>
        <p class="u_margin-bottom">{{ $t("pages.facilities.main.p1") }}</p>
        <img
          class="u_margin-top-30"
          :src="$imgUrl('facilities/Facilities_4.jpg')"
          alt="club"
        />
        <h3 class="heading-h3 u_text-center u_margin-top-30">
          {{ $t("pages.facilities.main.sub1") }}
        </h3>
        <p>{{ $t("pages.facilities.main.p2") }}</p>
        <h3 class="heading-h3 u_text-center u_margin-top-30">
          {{ $t("pages.facilities.main.sub2") }}
        </h3>
        <p>{{ $t("pages.facilities.main.p3") }}</p>
        <h3 class="heading-h3 u_text-center u_margin-top-30">
          {{ $t("pages.facilities.main.sub3") }}
        </h3>
        <p>{{ $t("pages.facilities.main.p4") }}</p>
        <h3 class="heading-h3 u_text-center u_margin-top-30">
          {{ $t("pages.facilities.main.sub4") }}
        </h3>
        <p>{{ $t("pages.facilities.main.p5") }}</p>
      </section>
      <section class="content_box">
        <h2 class="heading-h1">
          {{ $t("pages.facilities.main.title2") }}
        </h2>
        <p>{{ $t("pages.facilities.main.p6") }}</p>
        <h3 class="heading-h3 u_text-center u_margin-top-30">
          {{ $t("pages.facilities.main.sub5") }}
        </h3>
        <p>{{ $t("pages.facilities.main.p7") }}</p>
        <h3 class="heading-h3 u_text-center u_margin-top-30">
          {{ $t("pages.facilities.main.sub6") }}
        </h3>
        <p>{{ $t("pages.facilities.main.p8") }}</p>
        <h3 class="heading-h3 u_text-center u_margin-top-30">
          {{ $t("pages.facilities.main.sub7") }}
        </h3>
        <p class="u_margin-bottom">{{ $t("pages.facilities.main.p9") }}</p>
      </section>
    </div>
  </article>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import { getIdFromURL } from "vue-youtube-embed";

export default {
  data: () => ({
    hooperSettings: {
      centerMode: true,
      infiniteScroll: true,
      wheelControl: false,
      autoPlay: true,
      playSpeed: "5000",
      hoverPause: false,
      trimWhiteSpace: true,
    },
    carouselImages: [
      "facilities/Facilities_0.jpg",
      "facilities/Facilities_1.jpg",
      "facilities/Facilities_2.jpg",
      "facilities/Facilities_3.jpg",
      "facilities/ujezdzalnia.jpg",
      "facilities/Facilities_8.jpg",
    ],
  }),
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  methods: {
    getIdFromURL,
  },
};
</script>
