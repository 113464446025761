<template>
  <div id="app">
    <app-header />
    <menu-slider
      :width="300"
      format="full"
      direction="right"
      :opacity="0.15"
      ref="mobile_menu"
    />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <app-footer />
  </div>
</template>

<script>
import MenuSlider from "./components/menu-slider";
import AppHeader from "./components/app-header";
import AppFooter from "./components/app-footer";

export default {
  name: "App",
  components: {
    MenuSlider,
    AppHeader,
    AppFooter,
  },
};
</script>
<style src="./assets/output.css"></style>
