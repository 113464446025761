<template>
  <hooper :settings="hooperSettings" :style="{ height: 'auto' }">
    <slide v-for="(picture, index) in pictures" :key="index">
      <div
        class="slider_responsive"
        :style="{
          'background-image': `url(${$imgUrl(picture)})`,
        }"
      ></div>
    </slide>
    <hooper-pagination v-if="pictures.length > 1" slot="hooper-addons" />
  </hooper>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";

export default {
  props: {
    pictures: {
      type: Array,
      default: () => [],
    },
  },
  data: ({ pictures }) => ({
    hooperSettings: {
      centerMode: true,
      infiniteScroll: pictures.length > 1,
      wheelControl: false,
      touchDrag: true,
      mouseDrag: false,
      autoPlay: pictures.length > 1,
      playSpeed: "5000",
      hoverPause: false,
      trimWhiteSpace: true,
    },
  }),
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
};
</script>
