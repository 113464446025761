<template>
  <div class="flags">
    <button
      v-for="lang in languages"
      :key="lang.id"
      @click="changeLocale(lang)"
      :ref="lang.flag"
    >
      {{ lang.language.toUpperCase() }}
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({
    languages: [
      { id: 2, flag: "pl", language: "pl" },
      { id: 1, flag: "us", language: "eng" },
    ],
  }),
  mounted() {
    let defaultItem = { flag: "pl", language: "pl" };

    if (this.$route.meta.language) {
      defaultItem = this.$route.meta;
    } else if (localStorage.getItem("i18n")) {
      this.languages.forEach((item) => {
        if (item.language === localStorage.getItem("i18n")) {
          defaultItem = item;
        }
      });
    }
    this.$i18n.locale = defaultItem.language;
    this.$refs[defaultItem.flag][0].classList.add("selected");
    this.$store.dispatch('updateDataLocale', this.$i18n.locale)
  },
  watch: {
    $route() {
      if (this.$route.meta.language) {
        this.changeLocale(this.$route.meta);
      }
    },
  },
  methods: {
    changeLocale(lang) {
      localStorage.setItem("i18n", lang.language);
      this.$i18n.locale = lang.language;
      Object.values(this.$refs)
        .flat()
        .forEach((item) => {
          item.classList.remove("selected");
        });
      this.$refs[lang.flag][0].classList.add("selected");
      this.$store.dispatch('updateDataLocale', this.$i18n.locale)
    },
  },
};
</script>
