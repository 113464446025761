export default [
  {
    id: 1,
    slug: "PANTO Formel A",
    name: "Formel A",
    picture: "panto.png",
    pdf: "PANTO-Formel-A.pdf",
    texts: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  {
    id: 4,
    slug: "PANTO Apfel-Müsli",
    name: "Apfel Müsli",
    picture: "panto_apfel.png",
    pdf: "PANTO-Apfel-Musli.pdf",
    texts: [0, 1, 2, 3, 4, 5],
  },
  {
    id: 5,
    slug: "PANTO Aktiv-Müsli",
    name: "Aktiv Müsli",
    picture: "panto_aktiv.png",
    pdf: "PANTO-Aktiv-Musli.pdf",
    texts: [0, 1, 2, 3, 4, 5],
  },
  {
    id: 6,
    slug: "PANTO Kräuter-Müsli",
    name: "Kräuter-Müsli",
    picture: "panto_krauter.png",
    pdf: "PANTO-Krauter-Musli.pdf",
    texts: [0, 1, 2, 3, 4],
  },
  {
    id: 7,
    slug: "PANTO Zucht und Fohlen Müsli",
    name: "Zucht und Fohlen Müsli",
    picture: "panto.png",
    pdf: "PANTO-Zucht-und-Fohlenmusli.pdf",
    texts: [0, 1, 2, 3, 4, 5, 6],
  },
  {
    id: 8,
    slug: "Sieczka Pure Nature",
    name: "Sieczka Pure Nature",
    picture: "panto.png",
    texts: [0, 1, 2, 3, 4, 5],
  },
  {
    id: 3,
    slug: "PANTO Pferdemineral",
    name: "Witaminy Pferdemineral",
    picture: "panto_pferdemineral.png",
    texts: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  {
    id: 10,
    slug: "Leckerli-Mix",
    name: "Leckerli-Mix - Cukierki dla koni",
    picture: "leckerli_mix.png",
    texts: [0, 1, 2, 3, 4, 5],
  },
];
