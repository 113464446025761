export default [
    {
        name: "Diadem",
        birth: 2018,
        height: "-",
        movies: ["https://youtu.be/9iaEeCmit0E"],
        pictures: ["DIADEM_4.jpg", "DIADEM_5.jpg", "DIADEM_6.jpg", "DIADEM_7.jpg", "DIADEM_8.jpg", "DIADEM_3.jpg", "DIADEM_2.jpg", "DIADEM_0.jpg", "DIADEM_1.jpg", "DIADEM_9.jpg"],
        cover: "DIADEM_2.jpg",
        thumb: "DIADEM_thumb.jpg",
        sex: "Gelding",
        tree: "Diadem_tree.svg",
        slug: "diadem",
        sport: "-",
        texts: [
          0,
          1,
          ["2a", { url: "shinjuku" }, "2b", { url: "chaccolatte" }, "2c"],
          ["3a", { url: "calinda-pj" }, "3b"],
          4,
        ],
      },
      {
        name: "Lordanz",
        birth: 2010,
        height: "1.72m",
        movies: [
          "https://www.facebook.com/watch/?v=596519080755093",
          "https://www.facebook.com/watch/?v=1647470222053885",
          "https://www.facebook.com/watch/?v=1008642842830526"
        ],
        pictures: [
          "LORDANZ_12.jpg",
          "LORDANZ_13.jpg",
          "LORDANZ_14.jpg",
          "LORDANZ_15.jpg",
          "LORDANZ_16.jpg",
          "LORDANZ_17.jpg",
          "LORDANZ_18.jpg",
          "LORDANZ_19.jpg",
          "LORDANZ_20.jpg",
          "LORDANZ_11.jpg",
          "LORDANZ_0.jpg",
          "LORDANZ_1.jpg",
          "LORDANZ_2.jpg",
          "LORDANZ_3.jpg",
          "LORDANZ_4.jpg",
          "LORDANZ_5.jpg",
          "LORDANZ_6.jpg",
          "LORDANZ_7.jpg",
          "LORDANZ_8.jpg",
          "LORDANZ_9.jpg",
          "LORDANZ_10.jpg",
        ],
        cover: "LORDANZ_12.jpg",
        thumb: "LORDANZ_thumb.jpg",
        sex: "Gelding",
        tree: "Lordanz_tree.svg",
        slug: "lordanz",
        sport: "1.45m sj",
        texts: [0, 1, ["2a", { url: "gundi" }, "2b"], 3],
      },
      {
        name: "Chaccolindo",
        birth: 2020,
        height: "-",
        movies: [],
        pictures: [
          "CHACCOLINDO_5.jpg",
          "CHACCOLINDO_6.jpg",
          "CHACCOLINDO_7.jpg",
          "CHACCOLINDO_8.jpg",
          "CHACCOLINDO_9.jpg",
          "CHACCOLINDO_0.jpg",
          "CHACCOLINDO_1.jpg",
          "CHACCOLINDO_2.jpg",
          "CHACCOLINDO_3.jpg",
          "CHACCOLINDO_4.jpg",
        ],
        cover: "CHACCOLINDO_10.jpg",
        thumb: "CHACCOLINDO_thumb.jpg",
        sex: "Stallion",
        tree: "Chaccolindo_tree.svg",
        slug: "chaccolindo",
        sport: "-",
        texts: [0, ["1a", { url: "gundi" }, "1b"], 2],
      },
      {
        name: "Dinamite",
        birth: 2022,
        height: "-",
        movies: [],
        pictures: ["DINAMITE_1.jpg", "DINAMITE_2.jpg", "DINAMITE_3.jpg"],
        cover: "DINAMITE_1.jpg",
        thumb: "DINAMITE_thumb.jpg",
        sex: "Stallion",
        tree: "Dinamite_tree.svg",
        slug: "dinamite",
        sport: "-",
        texts: [],
      },
      {
        name: "Shinjuku",
        birth: 2015,
        height: "1.68m",
        movies: [
          "https://youtu.be/vysH06gH4j4?si=lBu_cEoIs1SUvRjW",
          "https://www.facebook.com/watch/?v=746001820271179",
          "https://www.facebook.com/watch/?v=202836382355890",
          "https://youtu.be/B72XfI9-caI?si=5_RQDRgkA5sogLc2",
          "https://youtu.be/E-jQXCqqwFc?si=1BgkpXUGD15cF93v",
          "https://youtu.be/grjqzucyeoQ?si=Ks1fWJ_DEUaxd2h_",
          "https://youtu.be/yO4b0RVBHIs",
          "https://youtu.be/VG6ZC5saCMg"
        ],
        pictures: [
          "SHINJUKU_1.jpg",
          "SHINJUKU_2.jpg",
          "SHINJUKU_3.jpg",
          "SHINJUKU_4.jpg",
          "SHINJUKU_5.jpg",
          "SHINJUKU_6.jpg",
          "SHINJUKU_7.jpg",
          "SHINJUKU_8.jpg",
          "SHINJUKU_9.jpg",
          "SHINJUKU_10.jpg",
          "SHINJUKU_11.jpg",
          "SHINJUKU_12.jpg",
          "SHINJUKU_13.jpg",
          "SHINJUKU_14.jpg",
          "SHINJUKU_15.jpg",
          "SHINJUKU_16.jpg",
          "SHINJUKU_17.jpg",
          "SHINJUKU_18.jpg"
        ],
        cover: "SHINJUKU_5.jpg",
        thumb: "SHINJUKU_thumb.jpg",
        sex: "Gelding",
        tree: "Shinjuku_tree.svg",
        slug: "shinjuku",
        sport: "1.40m sj",
        texts: [
          0,
          1,
          ["2a", { url: "calinda-pj" }, "2b"],
          ["3a", { url: "diadem" }, "3b", { url: "chaccolatte" }, "3c"],
          4,
        ],
      },
      {
        name: "Hickstead Boy",
        birth: 2017,
        height: "1.66m",
        movies: [
          "https://www.facebook.com/EquiClubZamlyniec/videos/1211743049013762",
          "https://www.facebook.com/EquiClubZamlyniec/videos/745948922810452",
          "https://youtu.be/MEt4_VZyc48",
          "https://www.facebook.com/gestut.sprehe/videos/2379441102371888",
        ],
        pictures: [
          "HICKSTEAD_BOY_10.jpg",
          "HICKSTEAD_BOY_11.jpg",
          "HICKSTEAD_BOY_12.jpg",
          "HICKSTEAD_BOY_0.jpg",
          "HICKSTEAD_BOY_1.jpg",
          "HICKSTEAD_BOY_2.jpg",
          "HICKSTEAD_BOY_3.jpg",
          "HICKSTEAD_BOY_4.jpg",
          "HICKSTEAD_BOY_5.jpg",
          "HICKSTEAD_BOY_6.jpg",
          "HICKSTEAD_BOY_7.jpg",
          "HICKSTEAD_BOY_8.jpg",
          "HICKSTEAD_BOY_9.jpg",
        ],
        cover: "HICKSTEAD_BOY_11.jpg",
        thumb: "HICKSTEAD_BOY_thumb.jpg",
        sex: "Stallion",
        tree: "Hickstead_boy_tree.svg",
        slug: "hickstead-boy",
        sport: "-",
        texts: [0, 1, 2, 3, 4],
      },
      {
        name: "Bajrang",
        birth: 2014,
        height: "1.78m",
        movies: [
          "https://www.facebook.com/EquiClubZamlyniec/videos/1510754219275688",
          "https://youtu.be/jA1vBZ1tayI",
          "https://youtu.be/-A_W4we79Iw",
          "https://youtu.be/KX8-_e0XxkM",
        ],
        pictures: [
          "BAJRANG_10.jpg",
          "BAJRANG_11.jpg",
          "BAJRANG_12.jpg",
          "BAJRANG_13.jpg",
          "BAJRANG_0.jpg",
          "BAJRANG_1.jpg",
          "BAJRANG_2.jpg",
          "BAJRANG_3.jpg",
          "BAJRANG_5.jpg",
          "BAJRANG_6.jpg",
          "BAJRANG_7.jpg",
          "BAJRANG_8.jpg",
          "BAJRANG_9.jpg",
        ],
        cover: "BAJRANG_10.jpg",
        thumb: "BAJRANG_thumb.jpg",
        sex: "Stallion",
        tree: "Bajrang_tree.svg",
        slug: "bajrang",
        sport: "1.40m sj",
        texts: [0, 1, 2, 3],
      },
      {
        name: "Diascada",
        birth: 2014,
        height: "1.7m",
        movies: [
          "https://youtu.be/wDfHJdeuv9o",
          "https://youtu.be/xBoBwxyliPs",
          "https://www.facebook.com/EquiClubZamlyniec/videos/273438067151405",
        ],
        pictures: [
          "DIASCADA_10.jpg",
          "DIASCADA_11.jpg",
          "DIASCADA_6.jpg",
          "DIASCADA_7.jpg",
          "DIASCADA_8.jpg",
          "DIASCADA_9.jpg",
          "DIASCADA_0.jpg",
          "DIASCADA_1.jpg",
          "DIASCADA_3.jpg",
          "DIASCADA_4.jpg",
          "DIASCADA_5.jpg",
        ],
        cover: "DIASCADA_6.jpg",
        thumb: "DIASCADA_thumb.jpg",
        sex: "Mare",
        tree: "Diascada_tree.svg",
        slug: "diascada",
        sport: "1.25m sj",
        texts: [
          0,
          ["1a", { url: "constable-frh" }, "1b"],
          ["2a", { url: "lordanz" }, "2b", { url: "gundi" }, "2c"],
        ],
      },
      {
        name: "HiBi Joy",
        birth: 2021,
        height: "-",
        movies: [],
        pictures: [
          "HIBI_JOY_9.jpg",
          "HIBI_JOY_8.jpg",
          "HIBI_JOY_0.jpg",
          "HIBI_JOY_1.jpg",
          "HIBI_JOY_2.jpg",
          "HIBI_JOY_3.jpg",
          "HIBI_JOY_4.jpg",
          "HIBI_JOY_5.jpg",
          "HIBI_JOY_6.jpg",
          "HIBI_JOY_10.jpg",
        ],
        cover: "HIBI_JOY_2.jpg",
        thumb: "HIBI_JOY_thumb.jpg",
        sex: "Stallion",
        tree: "Hibi_Joy_tree.svg",
        slug: "hibi-joy",
        sport: "-",
        texts: [
          0,
          [
            "1a",
            { url: "kookie" },
            "1b",
            { url: "gundi" },
            "1c",
            { url: "lordanz" },
            "1d",
            { url: "diascada" },
            "1e",
            { url: "constable-frh" },
            "1f",
          ],
          ["2a", { url: "hickstead-boy" }, "2b"],
        ],
      },
      {
        name: "Calgary PJ",
        birth: 2008,
        height: "1.73m",
        movies: ["https://youtu.be/P-jSkBgfU1A"],
        pictures: [
          "CALGARY_PJ_4.jpg",
          "CALGARY_PJ_5.jpg",
          "CALGARY_PJ_6.jpg",
          "CALGARY_PJ_7.jpg",
          "CALGARY_PJ_8.jpg",
          "CALGARY_PJ_9.jpg",
          "CALGARY_PJ_0.jpg",
          "CALGARY_PJ_2.jpg",
          "CALGARY_PJ_3.jpg",
        ],
        cover: "CALGARY_PJ_4.jpg",
        thumb: "CALGARY_PJ_thumb.jpg",
        sex: "Mare",
        tree: "Calgary_PJ_tree.svg",
        slug: "calgary-pj",
        sport: "1.10m sj",
        texts: [0],
      },
      {
        name: "Vasanti",
        birth: 2015,
        height: "1.75m",
        movies: [
          "https://youtu.be/Cv0vnoRUVQk",
          "https://www.facebook.com/EquiClubZamlyniec/videos/1280606222294064",
        ],
        pictures: ["VASANTI_0.jpg", "VASANTI_1.jpg", "VASANTI_2.jpg"],
        cover: "VASANTI_0.jpg",
        thumb: "VASANTI_thumb.jpg",
        sex: "Gelding",
        tree: "Vasanti_tree.svg",
        slug: "vasanti",
        sport: "Intro Level",
        texts: [0, 1, ["2a", { url: "gundi" }, "2b"], 3],
      },
].map((horse) => ({
    ...horse,
    filter: 'sold'
}))
