import broodmares from './broodmares';
import sport from './sport';
import youngsters from './youngsters';
import sold from './sold';

export default (lang = 'pl') => ([
    ...broodmares,
    ...sport(lang),
    ...youngsters,
    ...sold
].map((horse, i) => ({
    ...horse,
    id: i
})));
