import Home from "../../pages/Home";
import PageNotFound from "../../pages/404";

export default [
  {
    path: "/",
    component: Home,
    name: "home",
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];
