<template>
  <article class="page_wrap">
    <section class="bg_full bg_full-no-margin">
      <div class="logo">
        <img :src="$imgUrl('misc/logo_full.png')" alt="full logo" />
      </div>
      <p
        class="u_width-45 u_text-center u_margin-top-30 u_margin-bottom-30 u_s_width-70"
      >
        {{ $t("pages.home.text_top") }}
      </p>
      <router-link
        :to="{ path: path('about') }"
        tag="button"
        class="button_primary"
      >
        {{ $t("pages.home.button_main") }}
      </router-link>
      <img :src="$imgUrl('misc/arrow_right.svg')" alt="" class="arrow_down" />
    </section>
    <section class="main slider_home">
      <h1 class="heading-h1 u_margin-bottom-60">
        {{ $t("pages.home.title1") }}
      </h1>
      <home-slider class="welcome_slider" />

      <h2 class="heading-h1 u_margin-bottom-30">
        {{ $t("pages.home.title3") }}
      </h2>
      <p class="u_width-60 u_margin-auto u_s_width-100 u_text-center">
        {{ $t("pages.about.p3") }}
      </p>
      <p
        class="u_width-60 u_margin-left-auto u_margin-right-auto u_s_width-100 u_text-center u_margin-top-30"
        v-html="$t('pages.about.p4')"
      />
      <img class="icon" :src="$imgUrl('misc/logo_tree.svg')" alt="icon" />
      <div class="home_row2">
        <home-area-slider />
        <app-map />
      </div>
      <router-link tag="a" :to="{ path: path('about') }" class="home_button">
        <button class="button_primary">
          {{ $t("pages.home.button_main") }}
        </button>
      </router-link>
      <div class="u_text-center u_margin-top-60">
        <h1 class="heading-h1">{{ $t("pages.contact.title") }}</h1>
        <p class="contact">{{ $t("pages.contact.company_name") }}</p>
        <p class="contact u_margin-bottom-30">
          {{ $t("pages.contact.nip") }}
        </p>
        <a
          :href="`tel:${$t('pages.contact.phone.number').split(' ').join('')}`"
          class="contact"
          >{{ $t("pages.contact.phone.title") }}
          {{ $t("pages.contact.phone.number") }}</a
        >
        <a
          :href="`mailto:${$t('pages.contact.mail')}`"
          class="contact u_margin-bottom-30"
          >{{ $t("pages.contact.mail") }}</a
        >
        <h3 class="heading-h3 u_text-center">
          {{ $t("pages.contact.address_title") }}
        </h3>
        <p class="contact">{{ $t("pages.contact.address1") }}</p>
        <p class="contact u_margin-bottom-30">
          {{ $t("pages.contact.address2") }}
        </p>
        <h3 class="heading-h3 u_text-center">
          {{ $t("pages.contact.headquarter_title") }}
        </h3>
        <p class="contact">{{ $t("pages.contact.headquarter1") }}</p>
        <p class="contact u_margin-bottom-30">
          {{ $t("pages.contact.headquarter2") }}
        </p>
      </div>
    </section>
  </article>
</template>

<script>
import HomeAreaSlider from "@/components/home-area-slider";
import HomeSlider from "@/components/home-slider";
import AppMap from "@/components/app-map";
import { trim } from "lodash";

export default {
  components: {
    HomeSlider,
    HomeAreaSlider,
    AppMap,
  },
  methods: {
    path(item) {
      return `/${trim(this.$t("menu.url." + item))}`;
    },
  },
};
</script>
