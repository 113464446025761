<template>
  <article class="page page_wrap horse-page">
    <template v-if="horse">
      <photo-slider :pictures="[horse.cover]" />
      <section v-if="horse.tree" class="label label_tree">
        <img :src="$imgUrl(`tree/${horse.tree}`)" :alt="horse.tree" />
      </section>
      <div v-if="horse.tree" class="label_tree-arrow"><div /></div>
      <section class="horse_page wrap">
        <router-link
          tag="a"
          :to="{ path: `/${trim($t('menu.url.horses'))}` }"
          class="back"
        >
          <img :src="$imgUrl('misc/arrow_left.svg')" alt="arrow" />
          <p class="u_italic">{{ $t("menu.back") }}</p>
        </router-link>
        <p class="horse_kind">
          {{ $t(`pages.filters.${horse.filter}`) }}
        </p>
        <h1 class="heading-h1 horse_name horse_name-name">{{ horse.name }}</h1>
        <div class="row">
          <p class="attribute_title">
            {{ $t("pages.horse.detail1") }}
          </p>
          <p class="attribute_value">
            {{ horse.birth }}
          </p>
        </div>
        <div class="row">
          <p class="attribute_title">
            {{ $t("pages.horse.detail2") }}
          </p>
          <p v-if="horse.sex" class="attribute_value">
            {{ $t(`pages.horse.${horse.sex}`) }}
          </p>
        </div>
        <div class="row">
          <p class="attribute_title">
            {{ $t("pages.horse.detail3") }}
          </p>
          <p class="attribute_value">
            {{ horse.height }}
          </p>
        </div>
        <div class="row no_border">
          <p class="attribute_title">
            {{ $t("pages.horse.detail4") }}
          </p>
          <p class="attribute_value">
            {{ horse.sport }}
          </p>
        </div>
        <horse-table v-if="horse.tableConfig" :config="horse.tableConfig" :name="horse.name" />
        <template v-for="(item, index) in horse.texts">
          <p v-if="isParagraph(item)" class="text" :key="index">
            {{ $t(`texts.horses.${horse.name}.${item}`) }}
          </p>
          <p v-else :key="index" class="text">
            <template v-if="isLink(item)">
              <a :href="$t(`texts.horses.${horse.name}.${item.url}`)">
                {{ $t(`texts.horses.${horse.name}.${item.url}`) }}
              </a>
            </template>
            <template v-else-if="isArray(item)">
              <span v-for="(t, i) in item" :key="toString(t) + i">
                <template v-if="isParagraph(t)">{{
                  $t(`texts.horses.${horse.name}.${t}`)
                }}</template>
                <i v-else-if="t.italic">{{ $t(`texts.horses.${horse.name}.${t.text}`) }}</i>
                <router-link v-else :to="{ path: url(t) }">{{
                  trim($t(`horses.${t.url}`))
                }}</router-link>
              </span>
            </template>
            <span v-else-if="item.bold" style="font-weight: 500;">
              {{ $t(`texts.horses.${horse.name}.${item.text}`) }}
            </span>
          </p>
        </template>
      </section>
      <horse-slider v-if="horse.pictures.length" :pictures="horse.pictures" />
      <movie-slider
        v-if="horse.movies.length"
        :movies="horse.movies"
        height="auto"
      />
      <router-link tag="button" :to="{ path }" class="button_primary">
        {{ $t("pages.horse.button_main") }}
      </router-link>
    </template>
  </article>
</template>
<script>
import { isObject, get, trim, isArray, toString } from "lodash";
import PhotoSlider from "../components/photo-slider";
import MovieSlider from "../components/movie-slider";
import HorseSlider from "../components/horse-slider";
import HorseTable from "../components/horse-table"

export default {
  data() {
    return {
      path: `/${trim(this.$t("menu.url.horses"))}`,
    };
  },
  computed: {
    slug: ({ $route }) => $route.params.horse,
    horses: ({ $store }) => $store.getters["getHorseData"],
    horse: ({ horses, slug }) => horses?.find((item) => item.slug === slug)
  },
  mounted() {
    if (!this.horse) {
      this.$router.push({ path: "/404" });
    }
  },
  watch: {
    $route() {
      if (!this.horse) {
        this.$router.push({ path: "/404" });
      }
    },
  },
  methods: {
    url(item) {
      return `/${trim(this.$t("menu.url.horses"))}/${item.url}`;
    },
    isParagraph(item) {
      return !isObject(item);
    },
    isLink(item) {
      return get(item, "type", null) === "link";
    },
    isArray(item) {
      return isArray(item);
    },
    toString,
    trim,
  },
  components: {
    PhotoSlider,
    MovieSlider,
    HorseSlider,
    HorseTable
  },
};
</script>
