<template>
  <div class="header_wrap" ref="app_header">
    <img :src="$imgUrl('misc/logo.png')" @click="goHome()" />
    <div class="header_menu">
      <router-link
        v-for="link in $store.getters.getMenu"
        :key="link.id"
        tag="a"
        :to="{ path: path(link) }"
        active-class="active"
        exact
      >
        {{ $t(`menu.${link.text}`) }}
      </router-link>
    </div>
    <lang-switch class="header_flags" />
  </div>
</template>
<script>
import LangSwitch from "./lang-switch";
import { trim } from "lodash";

export default {
  components: {
    LangSwitch,
  },
  data: () => ({
    lastScrollTop: 0,
    scrolled: 0,
  }),
  mounted() {
    window.addEventListener("scroll", this.scrollFunc);
  },
  methods: {
    path({ url }) {
      return trim(`/${this.$t(`menu.url.${url}`)}`);
    },
    goHome() {
      this.$router.history.push({ path: "/" });
    },
    scrollFunc() {
      this.scrolled = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      if (this.scrolled >= 200) {
        if (this.scrolled > this.lastScrollTop) {
          this.$refs["app_header"].style.opacity = "0";
          this.$parent.$refs["mobile_menu"].$el.style.opacity = "0";
        } else {
          this.$refs["app_header"].style.opacity = "1";
          this.$parent.$refs["mobile_menu"].$el.style.opacity = "1";
        }
      } else {
        this.$refs["app_header"].style.opacity = "1";
        this.$parent.$refs["mobile_menu"].$el.style.opacity = "1";
      }
      this.lastScrollTop = this.scrolled <= 0 ? 0 : this.scrolled;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollFunc);
  },
};
</script>
